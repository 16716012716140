import React from 'react'
import PropTypes from 'prop-types'
import ConditionalTag from './DynamicTag'


/**
 * "Smart" Caption component with optional credit text and anchor tag. Text is required between the opening and closing tags which will make up the 
 * description of the caption. This component can only have a single child which is the description text. The rest of the component is 
 * configurable via its optional parameters/attributes described below.
 * 
 * @param {string} credit - Cites credit for a caption using a <cite> tag. Prepended with an (emdash) — and appended to the description text.
 * @param {string} crediturltext - The portion of credit text that will be the anchor tag. If none of the crediurltext matches the credit 
 *  text or the crediturltext is omitted then there will be no anchor tag present in the credit for the caption.
 * @param {string} crediturl - The url to navigate to when the anchor tag set by the crediturltext. If there is no anchor tag then the url 
 *   will not be used. The url can be a relative to the site or external.
 * @param {string} inline - Standard html5 boolean attribute. When present (true), anchor tags created with credtiturl text will not use 
 *  a pop-up (target="_blank" rel="noopener noreferrer").
 *  When not present (false) anchor tags will pop-up using (target="_blank" rel="noopener noreferrer").
 * @param {string} noblockquote - Standard html5 boolean attribute. When present (true), the entire caption will be wrapped in a 
 * &lt;blockquote> tag. When not present (false) there will be no &lt;blockquote> tag.
  * @example // SmartCaption with just the required description
 * return (
 *   <SmartCaption>A binary hash tree</SmartCaption>
 * )
 * @example // SmartCaption with credit
 * return (
 *  <SmartCaption
 *    credit="Illustration by David Göthberg, no copyright."
 *  >
 *    A binary hash tree
 *  </SmartCaption>
 * )
 * @example // SmartCaption with credit and a clickable crediturltext
 * return (
 *  <SmartCaption
 *    credit="Illustration by David Göthberg, no copyright."
 *    crediturltext="David Göthberg"
 *    crediturl="https://en.wikipedia.org/wiki/User:Davidgothberg"
 *  >
 *    A binary hash tree
 *  </SmartCaption>
 * )
 */
const SmartCaption = ({children, credit, crediturltext, crediturl, inline, noblockquote}) => { 
  if (!credit) {
    return (
      <ConditionalTag
        condition={!noblockquote}
        wrapper={children => <blockquote>{children}</blockquote>}
      >
        <React.Fragment>
          <p>
          <small>
            {children}
          </small>
          </p>
        </React.Fragment>
     </ConditionalTag>
    )
  }
  
  if (crediturltext && crediturl && credit.includes(crediturltext)) {
    let anchorTag, 
        citeTag, 
        start = credit.indexOf(crediturltext), 
        end = start + crediturltext.length;

    anchorTag = inline
      ? (<a href={crediturl}>{crediturltext}</a>)
      : (<a href={crediturl} target="_blank" rel="noopener noreferrer">{crediturltext}</a>)

    citeTag = <cite>{credit.slice(0, start)} {anchorTag}{credit.slice(end)}</cite>

    return (
      <ConditionalTag
        condition={!noblockquote}
        wrapper={children => <blockquote>{children}</blockquote>}
      >
        <p>
        <small>
          {children}
          {noblockquote ?' — ' : null}
          {citeTag}
        </small>
        </p>
      </ConditionalTag>
    )
  }

  return (
  <ConditionalTag
    condition={!noblockquote}
    wrapper={children => <blockquote>{children}</blockquote>}
  >
    <p>
     <small>
       {children}
       {noblockquote ?' — ' : null}
       <cite>
         {credit}
       </cite>
     </small>
     </p>
   </ConditionalTag>
  )
}

SmartCaption.propTypes = {
  /**
   * Exactly one child is allowed which must be a string representing the 
   * description of the caption.
   */
  children: PropTypes.element.isRequired,
  
  /**
   * Credit text to be appended to the desctiption text
   */
  credit: PropTypes.string,

  /**
   * The portion of the credit text that should be a clickable link (anchor tag).
   */
  crediturltext: PropTypes.string,

  /**
   * The url to nvigate to when the crediturltext in the credit for a caption is clicked.
   */
  crediturl: PropTypes.string,

  /**
   * Standard html5 boolean attribute. true: anchor tags will not use a pop-up (target="_blank" rel="noopener noreferrer").
   *  false: anchor tags will pop-up using (target="_blank" rel="noopener noreferrer").
   */
  inline: PropTypes.bool,

  /**
   * Standard html5 boolean attribute. When present (true), the entire caption will be 
   * wrapped in a <blockquote> tag. When not present (false) there will be no <blockquote> wrapper.
   */
  noblockquote: PropTypes.bool,

}

export default SmartCaption

