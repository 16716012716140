import React from "react"
import { Link, graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SmartCaption from "../components/SmartCaption"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    /* add components to be rendered by the 
       MDXRenderer that renders the body here
    */
    const shortcodes = { SmartCaption }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <div style={{marginTop: '40px'}}>
          <h1 className='title is-4  has-text-warning'>{post.frontmatter.title}</h1>
          <p
            className='has-text-info'
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(.25),
              marginTop: rhythm(-.75),
            }}
          >
            <span role="img" aria-label="time and date clock emoji">🕔 </span>
            {post.frontmatter.date}
          </p>
        </div>
        <MDXProvider components={shortcodes}>
          <MDXRenderer>
            {post.body}
          </MDXRenderer>
        </MDXProvider>
        <hr
          style={{
            marginBottom: rhythm(1),
            borderTop: '1px solid #bbb',
            margin: '30px 0 10px'
          }}
        />
        <div style={{paddingBottom: '35px'}}>
        <Bio/>
        </div>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={`blog${previous.fields.slug}`} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={`blog${next.fields.slug}`} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
